import { convertProductsForVariant, convertStockLevelToListItems } from "../helpers/converters";
import { deleteRequest, getRequest, postRequest, putRequest } from ".";

export interface Product {
    id?: number;
    handle: string;
    taxes: TaxesEnum;
    title: string;
    tags: string[];
    vendor: string;
    featuredImage: string;
    descriptionHtml: string;
    isDeleted?: boolean;
    variants?: ProductVariant[];
}
export interface CreateProduct {
    title: string;
    vendor: string;
    tags: string[];
    descriptionHtml: string;
    merchantId?: number;
    price?: number;
    compareAtPrice?: number;
    barcode?: string;
    categoryName?:string
}
export interface ProductVariant {
    id?: number;
    sku: string;
    barcode: string;
    compareAtPrice: number;
    price: number;
    inventoryQuantity: number;
    reservedQuantity: number;
    weclappPackagingUnitId: string;
    packagingQuantity: number;
    stockLevels?: StockLevel[];
    b2bPrice?: number;
    minOrderQuantity?: number;
    product?: Product;
}

export interface StockLevel {
    id: number;
    sku?: string;
    shopifyProductId?: string;
    merchantId: number;
    variantId: number;
    price: number;
    amount: number;
    targetAmount: number;
    triggerAmount: number;
    targetRelation: number;
    isActive: boolean;
    variant?: ProductVariant;
}

export interface ProductListItem {
    id: string | number;
    shopifyUrl?: string;
    isDeleted?: boolean;
    merchantId?: string;
    variantId: string;
    sku: string;
    barcode: string;
    compareAtPrice: number;
    tags: string[];
    handle: string;
    title: string;
    vendor: string;
    featuredImage: string;
    descriptionHtml: string;
    price: number;
    amount: number;
    taxes: TaxesEnum;
    targetAmount?: number;
    triggerAmount?: number;
    targetRelation?: number;
    b2bPrice?: number;
    minOrderQuantity?: number;
    inventoryQuantity?: number;
    reservedQuantity?: number;
    packagingQuantity?: number;
    weclappPackagingUnitId?: string;
    isActive?: boolean;
    stockLevels?: StockLevel[];

}

export enum TaxesEnum {
    NORMAL = 'normal',
    REDUCED = 'reduced',
}

export interface MerchantProduct {
    price: number;
    amount: number;
}

export const getProducts = async (params?: Record<string, unknown>) => {
    const response = await getRequest('api/products', params) as any;
    const {data, pageCount, page, perPage, total} = response;
    return {data: convertProductsForVariant(data), pageCount, page, perPage, total};
}

export const markProductdeleted = async (id: string) => {
    const data = await deleteRequest(`api/products/${id}`)
    return data;
}

export const getStockLevels = async (params?: Record<string, unknown>) => {
    const response = await getRequest(`api/products/stock-levels`, params) as any;
    const {data, pageCount, page, perPage, total} = response;
    return {data: convertStockLevelToListItems(data), pageCount, page, perPage, total};
}
export const updateProduct = async (payload: Product): Promise<Product | undefined> => {
    const data = await putRequest(`api/dashboard/products/${payload.id}`, payload)
    return data as Product;
}

export const createProduct = async (merchantId: number, payload: CreateProduct) => {
    const data = await postRequest(`api/products/merchant/${merchantId}`, payload)
    return data as Product;
}


export const createProductVariant = async (id: number, payload: ProductVariant) => {
    const data = await postRequest(`api/dashboard/products/${id}/variants`, payload)
    return data as Product;
}

export const updateProductVariant = async (id: number, payload: Record<string, any>) => {
    const data = await putRequest(`api/dashboard/products/variants/${id}`, payload);
    return data as Product;
}

export const updateMerchantProduct = async (id: string, payload: Record<string, unknown>) => {
    const data = await putRequest(`api/products/stock-levels/${id}`, payload);
    return data as StockLevel;
}

export const activateMerchantProduct = async (id: string) => {
    const data = await putRequest(`api/products/stock-levels/${id}/activate`, {});
    return data as StockLevel;
}

export const bulkUpdateMerchantProduct = async (merchantId: string, payload: Record<string, unknown>) => {
    const data = await putRequest(`api/dashboard/merchants/${merchantId}/products/variants/stock`, payload);
    return data as StockLevel[];
}


export const attachProductToMerchant = async (id: string, merchantId?: number) => {
    const params = {merchantId, variantId: id};
    const data =  await postRequest(`api/products/stock-levels/attach`, {}, params);
    return data as Product;
}

export const detachProductFromMerchant = async (id: string) => {
    const data = await deleteRequest(`api/products/stock-levels/${id}`)
    return data;
}

export const attachAllProductsToMerchant = async (merchantId: number) => {
    const params = {merchantId};
    const data = await postRequest(`api/products/stock-levels/attach-all`, {}, params);
    return data as ProductVariant;
}

export const getProductsFromOrderLord = async (merchantId?: string) => {
    const data = await postRequest(`api/dashboard/merchants/${merchantId}/products/menu`, {});
    return data as ProductVariant[];
}
