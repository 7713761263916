import {Dialog} from "primereact/dialog";
import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {
    ProductListItem,
    getStockLevels
} from "../../../api/products";
import {Button} from "primereact/button";
import {FieldValues} from "react-hook-form";
import {setMenu } from "../../../api/menus";
import {updateCategoriesWithSkus } from "./util";

interface OwnProps {
    merchantId: number;
    closeModal: () => void;
    categories: any[];
    setCategories : (categories : any)=> void;
    currentCategory?: any;
}

const AttachMenuModal: React.FC<OwnProps> = ({merchantId, closeModal, categories, setCategories, currentCategory}) => {
    const [pagination, setPagination] = useState({page: 1, perPage: 1000, pageCount: 0, total: 0});
    const [products, setProducts] = useState<ProductListItem[] | null>();
    const [category, setCategory] = useState<string>('')
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);


    useEffect(() => {
        if (currentCategory) {
            setCategory(currentCategory.name)
            setSelectedProducts(currentCategory.products.map((product: any) => product.sku))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCategory])



    const attachProduct = async (id: string) => {
        setSelectedProducts((prev) => {
            if (!prev.includes(id)) {
                return [...prev, id];
            }
            return prev;
        });
    };
    const detachProduct = async (id: string) => {
        const updatedProducts = selectedProducts.filter((sku) => sku !== id);
        setSelectedProducts(updatedProducts);
    };
    const attachAllProducts = () => {
        setSelectedProducts(products?.map((product) => product.sku) || []);
    }

    const onSubmit = async (data: FieldValues, e?: BaseSyntheticEvent) => {
        if(categories?.length) {
            const updatedMenu = {categories: updateCategoriesWithSkus(categories)};
            if(currentCategory) {
                const updatedCategories = updatedMenu.categories.map((item: any) => {
                    if (item.name === currentCategory.name) {
                        return {
                            ...item,
                            products: selectedProducts
                        }
                    }
                    return item;
                });
                updatedMenu.categories = updatedCategories;
                const res = await setMenu(merchantId, updatedMenu)
                setCategories(res.categories)     
            } else {
                updatedMenu.categories.push({
                    name: category,
                    products: selectedProducts
                })
               const res = await setMenu(merchantId, updatedMenu)
                setCategories(res.categories)
            }
        } else {
            const res = await setMenu(merchantId, {
                categories: [{
                    name: category,
                    products: selectedProducts,
                }]
            });
            setCategories(res.categories)
        }
        closeModal();
    };

    useEffect(() => {
        const setProductData = async () => {
            const response = await getStockLevels({filters: {merchantId}, pagination})
            if (response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setProducts(data);
            }
        }
        setProductData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const dialogFooter = (<div className="flex justify-between gap-x-6 py-5">
        <Button
            className="border border-midblue text-white hover:text-midblue p-2 bg-midblue hover:bg-transparent"
            onClick={attachAllProducts}>Alle auswählem</Button>

        <Button
            className="border border-midblue text-white hover:text-midblue p-2 bg-midblue hover:bg-transparent"
            onClick={onSubmit}>Speichern</Button>
    </div>)
    return (<Dialog header={'Menu hinzufügen'} visible={true} style={{width: '50vw'}} onHide={closeModal} footer={dialogFooter}>
        <input type='text' disabled={currentCategory?.name} value={category} onChange={(e) => setCategory(e.target.value)}
               placeholder={'Menüname'} className={'border border-midblue p-2 w-full'}/>
        <ul className="divide-y divide-gray-100">

            {products?.map(product => (
                <li key={product.id} className="flex justify-between gap-x-6 py-5">
                    <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={product.featuredImage}
                         alt=""/>
                    <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{product.title}</p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{product.vendor} - {product.price}</p>
                    </div>
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        {selectedProducts.includes(product.sku) ? <Button icon="pi pi-minus" onClick={() => detachProduct(product.sku as unknown as string)}/> : <Button icon="pi pi-plus" onClick={() => attachProduct(product.sku as unknown as string)}/>}
                    </div>
                </li>
            ))}
        </ul>
    </Dialog>)
}

export default AttachMenuModal;
