export const updateMenuWithSkus = (menu : any) => {
    return {
        ...menu,
        categories: updateCategoriesWithSkus(menu.categories),
    };
}

export const updateCategoriesWithSkus = (categories : any) => {
    return categories.map((category : any) => {
        if (category?.products?.length && category.products[0]?.sku) {
            const skus = category.products.map((item : any) => item.sku);
            return { ...category, products: skus }; // Replace products with SKUs
        }
        return category;
    });
}
