import React, {useState, useEffect} from 'react';
import {DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import AttachMenuModal from "./AttachMenuModal";
import {getMenuByMerchantId, publishMenuByMerchantId, setMenu} from "../../../api/menus";
import {Product} from "../../../api/products";
import {updateCategoriesWithSkus} from "./util";
import { convertIntToPrice } from '../../../helpers/converters';

interface Props {
    merchantId: number;
}

const Menu: React.FC<Props> = ({merchantId}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [categories, setCategories] = useState([]);
    const [currentCategorie, setCurrentCategorie] = useState();
    const [expandedCategory, setExpandedCategory] = useState<string | null>('');
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

    const onRowExpand = (event: DataTableRowEvent) => {
        setExpandedCategory(event.data.name)
    };

    const onRowCollapse = (event: DataTableRowEvent) => {
        setExpandedCategory(null)
    };

    const closeModal = () => {
        setCurrentCategorie(undefined);
        setOpenModal(false);
    }

    const deleteCategory = (name: string) => async () => {
        const updatedCategories = categories.filter((item: any) => item.name !== name);
        const updatedMenu = {
            categories: updateCategoriesWithSkus(updatedCategories),
        }
        const res = await setMenu(merchantId, updatedMenu)
        setCategories(res.categories)
    }






    const reorderProducts = async (products: Product) => {
        const updatedCategories = categories.map((item: any) => {
            if (item.name === expandedCategory) {
                item.products = products;
            }
            return item;
        });    
        const updatedMenu = {categories: updateCategoriesWithSkus(updatedCategories)};
        const res = await setMenu(merchantId, updatedMenu)
        setCategories(res.categories)
    }


    useEffect(() => {
        const setCategoriesData = async () => {
            const response = await getMenuByMerchantId(merchantId);

            setCategories(response.categories)
        }
        setCategoriesData()
    }, [merchantId]);

    const allowExpansion = (rowData: any) => {
        return rowData.products!.length > 0;
    };

    const deleteCategoryBody = (rowData: any) => {
        return <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                       onClick={deleteCategory(rowData['name'])}/>
    }

    const priceBody = (rowData: any) => {
        return <p className={'text-secondary-1 whitespace-nowrap'}>{convertIntToPrice(rowData[`price`])}</p>
    }

    const rowExpansionTemplate = (data: any) => {
        console.log('rowExpansionTemplate',data)
        return (
            <div className="p-3">
                <DataTable value={data.products} reorderableColumns reorderableRows
                           onRowReorder={(e: any) => reorderProducts(e.value)}
                           tableStyle={{minWidth: '50rem'}}>
                    <Column rowReorder style={{width: '3rem'}}/>
                    <Column field="variant.product.title" header="Produkt" />
                    <Column field="price" header="Preis" body={priceBody} />
                    <Column field="sku" header="Sku" />
                </DataTable>
            </div>
        );
    };

    const publishMenu = async () => {
        await publishMenuByMerchantId(merchantId);
    }

    const createMenu = () => {
        setOpenModal(true);
    }

    const moveMenu = async (name: string) => {
        const categoriesWithSKU = updateCategoriesWithSkus(categories);
        const index = categoriesWithSKU.findIndex((item: any) => item.name === name);
        const newIndex = index - 1;
        if (newIndex < 0) return;
        const temp = categoriesWithSKU[newIndex];
        categoriesWithSKU[newIndex] = categoriesWithSKU[index];
        categoriesWithSKU[index] = temp;
        const res = await setMenu(merchantId, {categories: categoriesWithSKU})
        setCategories(res.categories)
    }

    const editMenu = (name: string) => {
        if (!categories) return;
        const currentCategorie = categories.find((item: any) => item.name === name);
        setCurrentCategorie(currentCategorie);
        setOpenModal(true);
    }

    const editCategoryBody = (rowData: any) => {
        return <Button icon="pi pi-pencil" className="p-button-rounded p-button-danger"
                       onClick={() => editMenu(rowData['name'])}/>
    }

    const moveUpBody = (rowData: any) => {
        return <Button icon="pi pi-chevron-up" className="p-button-rounded p-button-danger"
                       onClick={() => moveMenu(rowData['name'])}/>
    }

    return (
        <div className="card">
            {!!merchantId && openModal &&
                <AttachMenuModal 
                    merchantId={merchantId}
                    closeModal={closeModal}
                    categories={categories}
                    setCategories={setCategories} 
                    currentCategory={currentCategorie} />}
            <div className="flex flex-row justify-between mb-4 gap-2">
                <Button onClick={createMenu} label='Kategorie hinzufügen' className='border border-midblue bg-midblue hover:bg-transparent hover:text-midblue text-white py-1 px-2 mb-1'/>
                <Button onClick={publishMenu} label='Menu nach Justeat' className='border border-midblue bg-midblue hover:bg-transparent hover:text-midblue text-white py-1 px-2 mb-1'/>
            </div>
            <DataTable value={categories}
                       expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                       rowExpansionTemplate={rowExpansionTemplate}
                       onRowExpand={onRowExpand} onRowCollapse={onRowCollapse}
                       dataKey="name" tableStyle={{minWidth: '60rem'}}>
                <Column expander={allowExpansion} style={{width: '5rem'}}/>
                <Column field="name" header="Name"/>
                <Column field="move" header="" body={moveUpBody} />
                <Column field="edit" header="" body={editCategoryBody} />
                <Column field="delete" header="" body={deleteCategoryBody} />
            </DataTable>
        </div>
    );
}
export default Menu;
