import {getRequest, postRequest} from "./index";

interface Menu {
    name: string;
    products: string[];
}


export const getMenuByMerchantId = async (id: number) => {
    const data = await getRequest(`api/justeat/menu/${id}`);
    console.log('getMenuByMerchantId', data)
    return data as any;
}


export const setMenu = async (id: number, payload: any) => {
    const data = await postRequest(`api/justeat/menu/${id}`, payload);
    console.log(data)
    return data as any;
}

export const publishMenuByMerchantId = async (id: number) => {
    const data = await postRequest(`api/justeat/menu/${id}/publish`, {});
    console.log(data)
    return data as any;
}
